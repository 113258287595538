<template>
    <section id="actions">
        <div class="container text-center position-relative">
            <div class="title-effect position-relative">
                <h1 class="text-center title-text p-2">NOS ACTIONS</h1>
                <div class="background-text text-center">NOS ACTIONS</div>
            </div>
            <h3>Engagés dans la construction d'une CPTS depuis ****, les professionnels de santés
            ont réalisé un état des lieux et mené une réflexion sur plusieurs axes :</h3>
        </div>

        <div class="container">
            <div class="d-flex justify-content-around align-items-center">
                <h1 class="extra color-primary">01</h1>
                <h2><b class="color-primary">Améliorer</b> la prise en charge de la  santé de la population du territoire</h2>
            </div>
            <div class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quaerat cupiditate dolorem! Commodi quod nisi, repudiandae non voluptate officia voluptatibus fugiat natus quas nihil dolore perferendis, sint optio explicabo! Nesciunt?</div>
            <div class="d-flex justify-content-around align-items-center mt-5">
                <h2><b class="color-primary">Organiser</b>  les soins non programmées</h2>
                <h1 class="extra color-primary">02</h1>
            </div>
            <div class="text-center">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores nobis ea minus aliquam unde magni id animi repellendus necessitatibus, sed, odit sint vitae culpa eveniet beatae at vero laudantium omnis!</div>
            <div class="d-flex justify-content-around align-items-center mt-5">
                <h1 class="extra color-primary">03</h1>
                <h2><b class="color-primary">Renforcer</b> la coordination interprofessionnel </h2>
            </div>
            <div class="text-center">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam nesciunt necessitatibus amet eos natus commodi cumque corrupti esse maiores iure quos, adipisci pariatur magnam libero perspiciatis tempora ut ipsum accusamus.</div>
            <div class="d-flex justify-content-around align-items-center mt-5">
                <h2><b class="color-primary">Développer</b> les échanges entre les
                professionnelsde santé libéraux
                et les acteurs du secteur 
                médico-social et social.</h2>
                <h1 class="extra color-primary">04</h1>
            </div>
            <div class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, ab excepturi omnis placeat quas totam earum, cum explicabo ea unde atque magni enim. Nesciunt dolore deserunt perspiciatis accusantium inventore consequatur.</div>
            <div class="d-flex justify-content-around align-items-center mt-5">
                <h1 class="extra color-primary">05</h1>
                <h2><b class="color-primary">Favoriser</b> les parcours et protocoles de prises en charges.</h2>
            </div>
            <div class="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, ab excepturi omnis placeat quas totam earum, cum explicabo ea unde atque magni enim. Nesciunt dolore deserunt perspiciatis accusantium inventore consequatur.</div>
        
        </div>
    </section>
</template>
<script>
export default {
    name:"actions"
}
</script>
<style scoped>

</style>